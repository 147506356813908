@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
.App {
  min-height: 100%;
  height: 100vh;
  min-width: 100%;
  width: 100%;
  font-family: "Inter", sans-serifs;
  background: #f9fbfd;
  overflow-y: auto;
}
.th {
  font-weight: bold !important;
  font-size: 1.1rem !important;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input::placeholder,
.css-6od3lo-MuiChip-label {
  font-family: "Inter", sans-serifs;
}
button,
table,
tr,
th,
td {
  font-family: "Inter", sans-serifs !important;
  font-weight: 500 !important;
}
tbody > tr:hover {
  background-color: #f7f9fc !important;
}
td,
th {
  border-bottom: 1px solid #edf2f9 !important;
}
hr {
  border: 0.01px solid #edf2f9 !important;
}
.react-tel-input .form-control {
  height: 3.6rem !important;
}
.resendBtn {
  border: none;
  background: none;
  border-bottom: 1px solid #183b59;
  font-weight: bold;
  cursor: pointer;
  color: #183b59;
}
.resendBtn:hover {
  color: #265075;
}

.MuiCard-root {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  border: 1px solid #edf2f9 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.active {
  background: #183b59;
  color: white;
  transition: background 1s ease;
}
.not-active {
  color: black;
}
.active-text {
  color: #183b59;
  font-weight: 600;
}
.session-card {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  background: white;
}
.auth-container {
  background-image: url("/public/images/background2.png");
  background-repeat: no-repeat;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.date-box {
  display: grid;
  grid-template-columns: 1fr 1fr 0.1fr 0.1fr;
  gap: 1rem;
  margin: 1rem 0 0 0;
  align-items: center;
}
.date-box:focus-visible {
  outline: none;
  border: none;
}
.btn-disabled {
  background: rgb(238, 238, 238) !important;
  color: rgb(185, 185, 185) !important;
  pointer-events: none;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action-icon {
  color: #183b59;
  cursor: pointer;
}
.delete:hover {
  color: rgb(195, 9, 9);
  cursor: pointer;
}
.main-container {
  flex-grow: 1;
}
.add-a-product-card {
  width: 76%;
  margin: 0 auto;
}

.code-btn {
  border: none;
  font-weight: 500;
  border-radius: 0.3rem;
  padding: 0.5rem;
  background: #183b59;
  color: white;
  cursor: pointer;
  margin-left: auto;
}
.code-btn:hover {
  background: #265089;
}
.back-btn {
  width: 20%;
  border: none;
  font-weight: 500;
  border-radius: 0.3rem;
  padding: 0.4rem;
  color: #265075;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
}
.alert {
  display: flex;
  font-size: 1rem !important;
  align-items: center;
  background: #265075 !important;
  font-weight: bold !important;
}
.css-65z8o8-MuiButtonBase-root-MuiTab-root {
  font-weight: bold !important;
}
.add-cert-btn {
  border: none;
  font-weight: 500;
  border-radius: 0.3rem;
  padding: 0.5rem;
  color: #265075;
  cursor: pointer;
  margin-left: auto;
  background: none;
}

.search-input {
  background: rgb(230, 230, 230);
  width: 15%;
  min-width: 16rem;
  border-radius: 0.4rem;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  margin: 1rem 0;
}
.filter-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}
.base-container {
  padding: 1rem 2rem;
}
.css-6od3lo-MuiChip-label {
  font-family: "Inter", sans-serifs;
  font-size: 1rem;
}
.prod-card:hover {
  translate: 3px 2px;
  transition: all 0.3s ease;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: bold !important;
  font-size: 1rem !important;
}
.certificate-container {
  /* max-height: 30vh; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.certificate {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: rgb(241, 241, 241);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.role-capsule {
  display: block;
  padding: 0.6rem 1rem;
  width: 6rem;
  text-align: center;
  border-radius: 0.54rem;
}
.img-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
  background-color: rgb(237, 236, 236);
  width: 100px;
  height: 100px;
  border: 1px dashed gray;
  border-radius: 0.6rem;
}
.brand-text {
  background: linear-gradient(
    40deg,
    rgb(8, 236, 8),
    rgb(235, 101, 101),
    rgb(250, 9, 250),
    rgba(6, 214, 221, 0.555),
    rgb(0, 250, 0)
  );
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: text 10s ease-in infinite;
}
@keyframes text {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
.div-center {
  display: flex;
  justify-content: center;
}
.normal-para {
  font-size: 16px;
  display: flex;
}
.container {
  background: white;
  margin-left: 10rem;
  margin-right: 10rem;
  padding: 5rem;
}
.mt2 {
  margin-top: 2rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mt1 {
  margin-top: 1rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.mtSm {
  margin-top: 0.25rem;
}
.mbSm {
  margin-bottom: 0.25rem;
}
.capsule {
  background: green;
  padding: 0.4rem 0.6rem;
  color: white;
  width: 5rem;
  font-weight: bold;
  border-radius: 10rem;
  text-align: center;
  border: none;
  transition: all 0.5s ease;
}
.capsule-inactive {
  background: rgb(226, 3, 3);
}
.no-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  justify-content: center;
  gap: 1rem;
}
.thumb > img {
  height: 50px !important;
}
.fake_table:nth-child(even) {
  background-color: #ebeef3;
}
.hover-menu:hover {
  background: #f7f9fc !important;
}
.count {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
.count > p {
  color: white;
  background: #183b59;
  border-radius: 50%;
  font-size: 0.8rem;
  height: 20px;
  width: 20px;
  display: grid;
  place-content: center;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: 1px solid #e3ebf6 !important;
}
.css-1gsv261 {
  border-color: #e3ebf6 !important;
}
.css-11yukd5-MuiTabs-indicator,
.css-axpu1l {
  display: none !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(15rem, 100%), 1fr));
  grid-gap: 20px;
  align-items: stretch;
  place-items: center;
}

.img_style {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

@media (max-width: 1400px) {
  .add-a-product-card {
    width: 90%;
  }
  .manage-products {
    padding: 0;
    font-size: 0.7rem;
  }
  .css-6od3lo-MuiChip-label {
    font-size: 0.7rem;
  }
}

@media (max-width: 1000px) {
  .add-a-product-card {
    width: 97%;
  }
  .manage-products {
    padding: 0;
    font-size: 0.7rem;
  }
  .css-6od3lo-MuiChip-label {
    font-size: 0.7rem;
  }
}
@media (max-width: 500px) {
  .add-a-product-card {
    width: 97%;
  }
  .main-container {
    flex-grow: 1;
    padding: 1rem 0;
  }
}
