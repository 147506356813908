*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter',sans-serifs;
}
html{
  font-size: 14px;
  scroll-behavior: smooth;
}
/* .MuiInputBase-root{
  outline: black!important;
  font-family: 'Inter',sans-serif!important;
} */